"use client"
import { AuthProviders } from "@/components/providers/auth-providers";
import { NuqsAdapter } from 'nuqs/adapters/next/app'

// Themes
import { ThemeProvider } from "@/components/themes/theme-provider";

// Translations
import dynamic from 'next/dynamic'
const TranslationsProvider = dynamic(() => import('@/components/providers/translations/translations-provider'), { ssr: false })

import { useEffect } from "react";
import { useOrganization, useOrganizationList } from "@clerk/nextjs";
import { BreadcrumbProvider } from "@/components/navigation/breadcrumbs";

export default function AppProviders({ children }: { children: React.ReactNode }) {
    return <>
        <AppWithTranslation>
            <ThemeProvider
                attribute="class"
                defaultTheme="light"
            >
                <NuqsAdapter>
                        <AuthProviders>
                            <AppWithDefaultOrganization>
                                <BreadcrumbProvider>
                                {children}
                                </BreadcrumbProvider>
                            </AppWithDefaultOrganization>
                        </AuthProviders>
                </NuqsAdapter>
            </ThemeProvider>
        </AppWithTranslation>
    </>
}

function AppWithDefaultOrganization({ children }: { children: React.ReactNode }) {
    const { setActive, userMemberships, isLoaded: isLoadedList } = useOrganizationList({
        userMemberships: true,
    })
    const { isLoaded, organization } = useOrganization()
    useEffect(() => {
        if (!isLoaded || !isLoadedList || organization) return
        const org = userMemberships?.data?.[0]
        if (org) setActive?.({ organization: org?.organization?.id })
    }, [isLoaded, isLoadedList, userMemberships.data])
    return <>
        {children}
    </>
}

function AppWithTranslation({ children }: { children: React.ReactNode }) {
    return <>
        <TranslationsProvider>
            {children}
        </TranslationsProvider>
    </>
}