"use client";


import { createContext, useContext, useState, ReactNode } from "react";
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbLink, BreadcrumbSeparator, BreadcrumbPage, BreadcrumbEllipsis } from "@/components/ui/breadcrumb";
import { useTranslation } from "react-i18next";
import { usePathname } from "next/navigation";
import { Fragment } from "react";
import Link from "next/link";

interface BreadcrumbContextType {
    overrides: Record<string, string>;
    setOverride: (path: string, label: string) => void;
}

const BreadcrumbContext = createContext<BreadcrumbContextType>({
    overrides: {},
    setOverride: () => { },
});

interface BreadcrumbProviderProps {
    children: ReactNode;
}

export function BreadcrumbProvider({ children }: BreadcrumbProviderProps) {
    const [overrides, setOverrides] = useState<Record<string, string>>({});
    const setOverride = (path: string, label: string) => {
        setOverrides((prev) => ({ ...prev, [path]: label }));
    };

    return (
        <BreadcrumbContext.Provider value={{ overrides, setOverride }}>
            {children}
        </BreadcrumbContext.Provider>
    );
}

export function useBreadcrumb(): BreadcrumbContextType {
    return useContext(BreadcrumbContext);
}

export function Breadcrumbs() {
    const { t } = useTranslation("navigation");
    const pathname = usePathname();
    const { overrides } = useBreadcrumb();

    const crumbs = pathname.split("/").filter(Boolean);
    const maxCrumbs = 3;
    const shouldTruncate = crumbs.length > maxCrumbs;

    // Handle truncation
    const visibleCrumbs = shouldTruncate
        ? [crumbs[0], "...", crumbs[crumbs.length - 1]]
        : crumbs;

    return (
        <Breadcrumb>
            <BreadcrumbList>
                {/* Home link */}
                <BreadcrumbItem>
                    <BreadcrumbLink asChild>
                        <Link href="/">{t("home")}</Link>
                    </BreadcrumbLink>
                </BreadcrumbItem>
                {crumbs.length > 0 && <BreadcrumbSeparator />}
                {/* Render crumbs */}
                {visibleCrumbs.map((crumb, index) => {
                    const isEllipsis = crumb === "...";
                    const isLast = index === visibleCrumbs.length - 1;
                    const currPath = crumbs
                        .slice(0, shouldTruncate ? (index === 0 ? 1 : crumbs.length) : index + 1)
                        .join("/");

                    const displayText = overrides[`/${currPath}`] !== undefined ? overrides[`/${currPath}`] : t(crumb);
                    return (
                        <Fragment key={index}>
                            {isEllipsis ? (
                                <BreadcrumbEllipsis>...</BreadcrumbEllipsis>
                            ) : isLast ? (
                                <BreadcrumbItem>
                                    <BreadcrumbPage>{displayText}</BreadcrumbPage>
                                </BreadcrumbItem>
                            ) : (
                                <BreadcrumbItem>
                                    <BreadcrumbLink asChild>
                                        <Link href={`/${currPath}`}>{displayText}</Link>
                                    </BreadcrumbLink>
                                </BreadcrumbItem>
                            )}
                            {!isLast && !isEllipsis && <BreadcrumbSeparator />}
                        </Fragment>
                    );
                })}
            </BreadcrumbList>
        </Breadcrumb>
    );
}
