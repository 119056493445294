"use client"
// Auth
import { ClerkProvider } from '@clerk/nextjs'

// Localization
import {frFR, enUS} from '@clerk/localizations'

// Theme
import { dark } from '@clerk/themes'

// Hooks
import { useTranslation } from 'react-i18next'
import { useTheme } from 'next-themes'

export function AuthProviders({ children }: { children: React.ReactNode }) {
    const { i18n } = useTranslation()
    const { theme } = useTheme()
    const isDark = theme === 'dark'

    return <>
        <ClerkProvider publishableKey={process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY} 
        localization={ i18n.language === 'fr' ? frFR : enUS } 
        appearance={{baseTheme: isDark && dark || undefined}}>
            {children}
        </ClerkProvider>
    </>
}